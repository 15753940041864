<template lang="pug">
.detail-page(v-if="state.isReady")
  .detail-content(ref="scroller")
    MediaSwiper(:list="state.list", ref="mediaSwiperRef",
      :isSwiperState="!state.isTabFixed")
    .detail-box(:class="{ fixed: state.isTabFixed }")
      .tab-box-container
        .tab-box
          .tab(v-for="(item, i) in state.tabs",
            :class="{ active: i === state.choosedI}",
            @click="switchTab(i)") {{item}}
      .detail-imgs
        .detail-img-container
          img.detail-img(
            :src="state.descImg",
            v-if="!state.isImgError[0]",
            v-show="state.choosedI === 0",
            @error="state.isImgError[0] = true")
          .detail-container(
            v-if="!state.isImgError[1]",
            v-show="state.choosedI === 1",
            :class="{ complete: state.isClassImgCompleted }")
            img.detail-img(
              :src="state.classImg",
              @error="state.isImgError[1] = true"
              @load="state.isClassImgCompleted = true")
          img.error-img(v-show="(state.choosedI === 0 && state.isImgError[0]) || (state.choosedI === 1 && state.isImgError[1])",
            src="../assets/yd.png")
</template>

<script>
/* eslint-disable */
import {
  reactive, ref, onUnmounted, nextTick,
} from 'vue'
import { useRoute } from 'vue-router'
import service from '@/services/panda'
import MediaSwiper from '@/components/MediaSwiper.vue'

export default {
  name: 'Detail',
  components: {
    MediaSwiper,
  },
  setup() {
    const state = reactive({
      isTabFixed: false,
      list: [],
      choosedI: 0,
      isReady: false,
      descImg: '',
      classImg: '',
      tabs: [],
      swiperHeight: 504,
      isImgError: [],
      isClassImgCompleted: false,
    })
    const mediaSwiperRef = ref()

    const scroller = ref()
    const onScroll = () => {
      const { scrollTop } = scroller.value
      state.isTabFixed = scrollTop >= state.swiperHeight
      if (state.isTabFixed) {
        mediaSwiperRef.value.stopVideo()
      }
    }

    const reset = async () => {
      state.choosedI = 0
      state.isTabFixed = false
      state.isReady = false
      state.isImgError = []
      state.isClassImgCompleted = false
      await nextTick()
      state.isReady = true
      await nextTick()
      scroller.value.addEventListener('scroll', onScroll)
      window.ydk.stopPageLoading()
    }


    const getImgSrc = (src) => {
      if (!/\?/.test(src)) {
        return `${src}?imageView&type=webp`
      }
      return `${src}&type=webp`
    }
    const getDetail = (grade, tbversion, goodsId) => {
      window.ydk.startPageLoading()
      service.getDetail({ grade, tbversion, goodsId })
        .then(async (res) => {
          const { data } = res
          reset()
          state.descImg = data.descriptionImg ? getImgSrc(data.descriptionImg) : ''
          state.classImg = data.outlineImg ? getImgSrc(data.outlineImg) : ''
          state.tabs = data.outlineImg ? ['课程详情', '课程大纲'] : ['课程详情']
          const list = data.imgCarouselList ? data.imgCarouselList.map((item) => ({ url: getImgSrc(item) })) : []
          if (data.videoCarousel) {
            list.unshift(data.videoCarousel)
          }
          state.list = list
        }).catch(() => {
          reset()
          state.list = []
          state.classImg = ''
          state.descImg = ''
        })
    }

    window.ydk.setGoodInfo({}, (data) => {
      getDetail(data.grade, data.tbversion, data.goodsId)
    })

    window.ydk.setVideoState({}, (data) => {
      if (!mediaSwiperRef.value) {
        return
      }
      if (data.status === 1) {
        mediaSwiperRef.value.playVideo()
      } else {
        mediaSwiperRef.value.stopVideo()
      }
    })

    // getDetail('learner_pupil_one', '人教版', 101)
    window.ydk.jsLoadReady({
      state: 'completed',
    })

    const route = useRoute()
    const { grade, tbversion, goodsId } = route.query
    if (grade && tbversion && goodsId) {
      getDetail(grade, tbversion, goodsId)
    }

    const detail = ref()
    const switchTab = (i) => {
      state.choosedI = i
      state.isImgError[i] = false
      if (state.isTabFixed) {
        scroller.value.scrollTo(0, state.swiperHeight)
      }
    }

    onUnmounted(() => {
      scroller.value.removeEventListener('scroll', onScroll)
    })

    return {
      state,
      mediaSwiperRef,
      switchTab,
      detail,
      scroller,
    }
  },
};
</script>

<style lang="stylus">
body, html, #app
  background transparent
  font-family OPPOSans
</style>
<style lang="stylus" scoped>
.detail-page
  margin 0 0.18rem 0 0.48rem
  border-radius 0.32rem 0.32rem 0 0
  overflow hidden
  height 100vh
.detail-content
  height 100%
  overflow-y scroll
img
  display block
.tab-box
  padding 0.12rem
  font-size 0.2rem
  line-height 0.26rem
  display flex
  justify-content center
  color #7C7D82
  box-sizing border-box
.tab
  padding-bottom 0.07rem
  &:nth-child(2)
    margin-left 1.2rem
  &.active
    color #2A2B2E
    position relative
    font-family OPPOSans-Bold
    &::after
      content ''
      display block
      width 100%
      height 0.03rem
      border-radius 0.04rem
      background #15CDC8
      position absolute
      bottom 0
      left 0
.detail-box
  min-height 100vh
  box-sizing border-box
  background #fff
.tab-box-container
  position relative
  z-index 1
  background #fff
  border-radius 0.32rem 0.32rem 0 0
.fixed
  padding-top 0.57rem
  .tab-box-container
    position absolute
    width calc(100% - 0.66rem)
    left 0.48rem
    z-index 100
    top 0
.detail-imgs
  position relative
  padding 0 0.38rem
.detail-img-container, .detail-container
  width 100%
  min-height calc(100vh - 0.57rem)
  border-radius 0.2rem 0.2rem 0 0
  overflow hidden
  background #F0F2F5
.error-img
  width 1rem
  position absolute
  left 50%
  top 2.6rem
  transform translateX(-50%)
.detail-container
  padding-bottom 0.5rem
  box-sizing border-box
  &.complete
    background #fff
</style>
