<template lang="pug">
.media-swiper-comp
  swiper(
    v-if="list.length > 0"
    :slides-per-view="1"
    :space-between="0",
    @slideChange="onSlideChange")
    swiper-slide(v-for="(item, i) in list")
      .img-container(v-if="!item.thumbnail")
        img.img(v-if="!errorList[i]", :src="item.url", @error="onError(i)")
        img.error-img(v-if="errorList[i]", src="../assets/yd.png")
      .video-box(v-else, @click="switchVideoState")
        video.video(
          :poster="item.thumbnail",
          :ref="setItemRef(i)"
          :src="item.url", :webkit-playsinline="true",:playsinline="true",
          x5-video-player-type="h5-page", :controls="false")
        .mask(v-if="playState === -1 || isLoadingVisible")
        .error-tips(v-if="playState === -1") 播放失败，请检查网络
        .play-btn(v-show="[-1, 0].includes(playState) || isLoadingVisible",
          :class="{ loading: isLoadingVisible }")
  img.error-img(v-if="list.length === 0", src="../assets/yd.png")
  .pagenation
    .pagenation-item(:class="{ active: currentI >= i }", v-for="(item, i) in list")
</template>

<script>
import {
  defineComponent, onUnmounted, ref, watch,
} from 'vue'
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue'

export default defineComponent({
  name: 'MediaSwiper',
  props: {
    list: Array,
    isSwiperState: Boolean,
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  setup(props) {
    const videoRefs = ref([])
    const playState = ref(0) // -1表示错误，0 表示待播放，1表示loading，2表示播放中

    const currentI = ref(0)
    const currentTime = ref(0)

    const playVideo = () => {
      const video = videoRefs.value[currentI.value]
      if (video && props.isSwiperState) {
        if (playState.value === -1) {
          video.load()
          video.currentTime = currentTime.value
        }
        video.play()
        playState.value = 1
      }
    }

    const isLoadingVisible = ref(false)
    let timeId = null
    watch(() => playState.value, () => {
      clearTimeout(timeId)
      isLoadingVisible.value = false
      if (playState.value === 1) {
        timeId = setTimeout(() => {
          isLoadingVisible.value = true
        }, 300)
      }
    })

    const stopVideo = () => {
      const video = videoRefs.value[currentI.value]
      if (video) {
        video.pause()
        playState.value = 0
      }
    }

    const switchVideoState = () => {
      if (playState.value === 2) {
        stopVideo()
      } else {
        playVideo()
      }
    }

    const errorList = ref([])
    const onError = (i) => {
      errorList.value[i] = true
    }

    const onSlideChange = (e) => {
      stopVideo()
      currentI.value = e.activeIndex
      errorList.value[e.activeIndex] = false
    }

    const setItemRef = (index) => {
      const cb = (el) => {
        if (!videoRefs.value[index] && el) {
          videoRefs.value[index] = el
          el.addEventListener('ended', () => {
            el.load()
            playState.value = 0
          });
          el.addEventListener('error', () => {
            playState.value = -1
          });
          el.addEventListener('timeupdate', () => {
            const newTime = videoRefs.value[index].currentTime
            if ([1, 2].includes(playState.value)) {
              // eslint-disable-next-line
              playState.value = newTime > currentTime.value ? 2 : (newTime === currentTime.value ? 1 : 0)
            }
            currentTime.value = newTime
          });
        }
      }
      return cb
    }

    onUnmounted(() => {
      clearTimeout(timeId)
    })

    return {
      playState,
      videoRefs,
      switchVideoState,
      currentI,
      onSlideChange,
      setItemRef,
      playVideo,
      stopVideo,
      isLoadingVisible,
      errorList,
      onError,
    };
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="stylus" scoped>
@import '~@/styles/swiper.css'

.video-box, .media-swiper-comp
  position relative
  margin-bottom -0.32rem
.media-swiper-comp
  position relative
  height 5.36rem
  background #F0F2F5
.video-box, .video
  width 100%
.play-btn
  width 2rem
  height 2rem
  position absolute
  left 50%
  top 50%
  margin-left -1rem
  margin-top -1rem
  background url('~@/assets/ic_play.png') 50% 50% / 100% 100% no-repeat
  &.loading
    background-image url(../assets/ic_load.png)
    background-size 1.6rem 1.6rem
.pagenation
  position absolute
  bottom 0.18rem + 0.32rem
  display flex
  padding 0 0.22rem
  width 100%
  z-index 100
  box-sizing border-box
.pagenation-item
  flex 1
  height 0.04rem
  background rgba(42, 43, 46, 0.4)
  border-radius 0.1rem
  &.active
    background #fff
.pagenation-item + .pagenation-item
  margin-left 0.16rem
.video-box, .img-container
  height 5.36rem
.img
  height 100%
.video
  height 100%
  object-fit fill
.mask
  position absolute
  left 0
  top 0
  width 100%
  height 100%
  background rgba(0, 0, 0, 0.4)
.error-tips
  font-size 0.3rem
  color #fff
  position absolute
  bottom 0.8rem
  width 100%
  text-align center
.error-img
  width 1rem
  position absolute
  left 50%
  top 50%
  transform translate(-50%, -50%)
</style>
<style lang="stylus">
.play-btn.loading
  animation rotate 800ms linear infinite
@keyframes rotate
  0% {
    transform: rotate(0)
  }
  50% {
    transform: rotate(180deg)
  }
  100% {
    transform: rotate(360deg)
  }
</style>
